import React, { createRef } from "react"
import './ModalTemplate.css'
import { AppTools } from "../../types"
import { GoBack } from ".."

type ModalTemplateProps = {
    children: React.ReactNode,
    appTools: AppTools,
    onGoBack?: () => void
}

const ModalTemplate = (props: ModalTemplateProps) => {
    const { children, appTools, onGoBack } = props;

    const mainRef = createRef<HTMLDivElement>();
    
    const triggerOnClose = () => {
        onGoBack && onGoBack();
        if(mainRef.current){
            mainRef.current!.style.animationName = "slideout";
            setTimeout(() => {
                if(mainRef.current){
                    mainRef.current!.style.opacity = "0";
                }
                appTools.popDisplay();
            },400);
        }else{
            appTools.popDisplay();
        }
    }

    return (
    <div ref={mainRef} className="modal-template-main">
        <div className="modal-template-goback">
            <GoBack onGoBack={triggerOnClose} />
        </div>
        <div className="modal-template-content">
            {children}
        </div>
    </div>)
} 

export default ModalTemplate;