import React, { CSSProperties } from "react";
import './ButtonLadderPermit.css';
import { AppTools, StepladderType } from "../types.ts";
import { StepladderPermit } from "../pages";
import { BLUE_PRIMARY, RED_PRIMARY } from "../const.ts";

type ButtonLadderPermitProps = {
    appTools: AppTools,
    ladderPermitType: StepladderType | undefined,
    setLadderPermitType: (value: StepladderType | undefined) => void,
    style?: CSSProperties
}

const ButtonLadderPermit = (props: ButtonLadderPermitProps) => {
    const { appTools, ladderPermitType, setLadderPermitType, style } = props;

    const triggerOnClick = () => {
        if(ladderPermitType === undefined){
            appTools.pushDisplay(<StepladderPermit appTools={appTools} validateLadder={setLadderPermitType} />)
        }else{
            setLadderPermitType(undefined);
        }
    }

    return (<div className="petra-button button-ladder-permit" 
    style={{...style, backgroundColor: (ladderPermitType === undefined ? BLUE_PRIMARY : RED_PRIMARY)}} 
    onClick={triggerOnClick}>
        {ladderPermitType === undefined ? (
            <p>Utiliser permis échelle/escabeau</p>
        ) : (
            <p>Annuler permis échelle/escabeau</p>
        )}
    </div>)
}

export default ButtonLadderPermit;