import React, { useEffect, useState } from "react";
import './EquipmentsView.css';
import { EPCResponse, EPIResponse } from "../../api/responses.ts";
import { AppTools } from "../../types.ts";
import { EquipmentsDetail } from "..";

type EquipmentsViewProps = {
    EPIs?: EPIResponse[],
    EPCs?: EPCResponse[],
    workOrderId: number,
    appTools: AppTools
};

type DisplayViewEvent = {
    openView?: () => void,
}

const EquipmentsView = (props: EquipmentsViewProps) => {

    const {EPIs, EPCs, workOrderId, appTools} = props;

    const [title, setTitle] = useState<string>("");
    const [equisDesc, setEquisDesc] = useState<string[]>([]);
    const [clickEvent, setClickEvent] = useState<DisplayViewEvent>({});

    useEffect(() => {
        if(EPIs === undefined && EPCs === undefined){
            throw new Error("Aucun équipement n'a été fourni pour l'affichage");
        }	
        if(EPIs !== undefined && EPCs !== undefined){
            throw new Error("Impossible d'afficher les EPIs et les EPCs en même temps");
        }

        if(EPIs !== undefined){
            initEPIs();
        }else{
            initEPCs();
        }
        return () => {
            setClickEvent({});
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initEPIs = () => {
        setTitle("Liste EPI");
        const equis: string[] = [];
        EPIs!.forEach(epi => {
            equis.push(epi.epiName);
        });
        setEquisDesc(equis);
        setClickEvent({openView: openEPIView});
    };

    const initEPCs = () => {
        setTitle("Liste EPC");
        const equis: string[] = [];
        EPCs!.forEach(epc => {
            equis.push(epc.epcName);
        });
        setEquisDesc(equis);
    };

    const openEPIView = () => {
        appTools.pushDisplay(<EquipmentsDetail workOrderId={workOrderId} epis={EPIs!} onClose={() => appTools.popDisplay()} />)
    };

    return (
        <div className="equi-main">
            <div className="equi-header">
                <p className="equi-title">{title}</p>
                {clickEvent.openView !== undefined && (
                    <p className="equi-detail" onClick={() => {clickEvent.openView!()}}>Détail</p>
                )}
            </div>
            {equisDesc.length !== 0 && equisDesc.map((desc, index) => {
                return <p key={index} className="equi-description">{desc}</p>
            })}
        </div>
    );
};

export default EquipmentsView;