import React, { CSSProperties } from 'react';
import './LoadingView.css';

type LoadingViewProps = {
    style?: CSSProperties
}

const LoadingView = (props: LoadingViewProps) => {
    return (
        <div style={props.style} className="loadingio-spinner-pulse-k6ecr35clk">
            <div className="ldio-y8vj8sgdk28">
                <div></div><div></div><div></div>
            </div>
        </div>
    );
}

export default LoadingView;