import config from "./config.ts";
const { apiBaseUrl } = config;

export const COOKIES_TOKEN = "PS_JWT";
export const LOCAL_STORAGE_IDENTIFIER = "PS_IDENTIFIER";
export const TOKEN_HEADER = 'x-token';
export const X_PAGINATION = 'x-pagination';
export const ERR_API_CODE_INVALID_TOKEN = 2;
export const ERR_API_CODE_EXPIRED_TOKEN = 3;
export const BASE_URL = apiBaseUrl;
export const KEY_STORAGE_SEARCH = "PS_SEARCH";

export const BLUE_PRIMARY = "#3DBFF1";
export const BLUE_SECONDARY = "#5ACAF2";

export const YELLOW_PRIMARY = "#F1C50B";
export const YELLOW_SECONDARY = "#F1CE47";

export const ORANGE_PRIMARY = "#FD9700";

export const GREEN_PRIMARY = "#4CB050";

export const PURPLE_PRIMARY = "#B04CB0";
export const PURPLE_SECONDARY = "#C764C7";

export const PURPLE_BLUE_PRIMARY = "#7786D1";
export const PURPLE_BLUE_SECONDARY = "#8B9AD1";

export const RED_PRIMARY = '#FF5253';

export const BACKGROUND_PRIMARY = '#EFEFEF';
export const BLACK = "#000000";
export const WHITE = "#FFFFFF";