import { useEffect, useState } from "react";
import { KEY_STORAGE_SEARCH } from '../const.ts';

const useSearchStorage = () => {

    const [searchData, setSearchData] = useState<Object>(JSON.parse(localStorage.getItem(KEY_STORAGE_SEARCH) ?? "{}"))

    useEffect(() => {
        localStorage.setItem(KEY_STORAGE_SEARCH, JSON.stringify(searchData));
    }, [searchData])

    const setData = <TData,> (key: string, search: TData) => {
        setSearchData(prevSearchData => {
            let newSearchData = {...prevSearchData}; 
            newSearchData[key] = search;
            return newSearchData;
        });
    }

    const getData = <TData,> (key: string): TData | null => {
        if(!searchData) return null;
        if(!searchData[key]) return null;
        return searchData[key] as TData;
    }

    return [getData, setData] as const;
}

export default useSearchStorage;