import React, { CSSProperties, useEffect, useState } from "react";
import './Badges.css';

type BadgeDirection = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

type BadgesProps = {
    text: string,
    backgroundColor?: string,
    color?: string,
    direction?: BadgeDirection,
    styleBox?: CSSProperties,
    styleText?: CSSProperties,
}

const length = "2px";
const blur = "10px";
const spread = "0px";
const colorShadow = "rgba(0,0,0,0.25)";

/**
 * Affichage d'un badge
 * 
 * ⚠️ **Pour fonctionner, il faut que le conteneur parent possède la propriété** `position: relative;`
 */
const Badges = (props: BadgesProps) => {
    const { text, backgroundColor, color, direction = 'top-right', styleBox, styleText } = props;

    const [cssBox, setCssBox] = useState<CSSProperties>({});

    useEffect(() => {
        switch(direction){
            case "top-left":
                setCssBox({top:0, left:0, 
                    boxShadow: `${length} ${length} ${blur} ${spread} ${colorShadow}`});
                break;
            case "top-right":
                setCssBox({top:0, right:0,
                    boxShadow: `-${length} ${length} ${blur} ${spread} ${colorShadow}`});
                break;
            case "bottom-left":
                setCssBox({bottom:0, left:0,
                    boxShadow: `${length} -${length} ${blur} ${spread} ${colorShadow}`});
                break;
            case "bottom-right":
                setCssBox({bottom:0, right:0,
                    boxShadow: `-${length} -${length} ${blur} ${spread} ${colorShadow}`});
                break;
        }
    }, [direction])

    return (
        <div className="badges-main" style={{...cssBox, ...styleBox, backgroundColor: backgroundColor}}>
            <p style={{...styleText, color: color}}>{text}</p>
        </div>
    );
}

export default Badges;