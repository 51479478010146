import React, { createRef, useCallback, useEffect } from "react";
import { ConsultTableHeader } from "../compTypes";
import { AppTools } from "../../types";

type ConsultTableValueProps = {
    value: string,
    header: ConsultTableHeader,
    appTools: AppTools
}


const consultTableHeightPxContent = 69;

const isOverflown = (element: HTMLElement): boolean => {
    return (consultTableHeightPxContent *0.55) < element.clientHeight;
}

const ConsultTableValue = (props: ConsultTableValueProps) => {
    const { value, header, appTools } = props;

    const paragraphRef = createRef<HTMLParagraphElement>();

    const adaptDisplayOverflowing = useCallback((element : HTMLElement, header: ConsultTableHeader, value: string) => {
        if(isOverflown(element)){
            element.style.textDecoration = "underline";
            element.style.cursor = "pointer";
            element.innerText = "Visualiser ...";
            element.onclick = () => {
                appTools.displayText(header.title, value);
            }
        }else{
            element.style.textDecoration = "none";
            element.style.cursor = "default";
            element.innerText = value;
        }
    }, [appTools]);

    useEffect(() => {
        if(paragraphRef.current === null){
            return;
        }
        adaptDisplayOverflowing(paragraphRef.current, header, value);
    }, [value, paragraphRef, adaptDisplayOverflowing, header])

    return (
        <p className="consultTable-table-element-value" 
            ref={paragraphRef}>
            {value}
        </p>
    )
}

export default ConsultTableValue;