import React, { createRef, useEffect, useState } from 'react';
import './DeclareSignature.css'
import { MultiplePhoneImg, OnePhoneImg, OneShotSignatureImg } from '../../images';
import { AppTools, EquipmentsChanging, generateCommentary, getCommentByUserRole, MeasureType, MessageViewType, Role, SignatureTypeDeclaration, StepladderType } from '../../types.ts';
import { useNavigate } from 'react-router-dom';
import { DeclareOptionalSignModel, DeclareSignModel, MeasureModel } from '../../api/model.ts'
import { useRequest } from '../../hooks/index.js';
import { BASE_URL } from '../../const.ts';
import { DeclareSignResponse, ApiError, WorkOrderDetailReponse } from '../../api/responses.ts';
import { IS_CREATOR_ARG_ROUTE, ONE_PHONE_SIGNATURE_ROUTE, QRCODE_ROUTE, SIGNATURE_STATE_ROUTE, SIGN_TYPE_ARG_ROUTE, SIGN_TYPE_FIRST_ARG_ROUTE, WORK_ORDER_ARG_ROUTE } from '../../route.ts';
import { ButtonLadderPermit, CommentaryView, EquipmentsEditButton, MeasureView, NbWorkerView } from '../index.js';

type DeclareSignatureProps = {
    appTools: AppTools,
    onClose: () => void,
    mainWorkOrder: WorkOrderDetailReponse,
    mainWorkOrderLabelle: string,
    userRoles: Role[],
    declarationType : SignatureTypeDeclaration,
    workOrderChildrens?: {id: number, labelle: string}[],
    customToken?: string,
    triggerReloadWorkOrder?: () => void
}

const DeclareSignature = (props: DeclareSignatureProps) => {
    const {appTools, onClose, mainWorkOrder, mainWorkOrderLabelle, userRoles, declarationType, workOrderChildrens, customToken, triggerReloadWorkOrder} = props;
    const navigate = useNavigate();
    const [postWithResponse, post, get] = useRequest(BASE_URL, props.appTools, customToken);
    const allWorkOrder: {id: number, labelle: string}[] = [{id: mainWorkOrder.workId, labelle: mainWorkOrderLabelle}, ...(workOrderChildrens ? workOrderChildrens! : [])];
    const containLadderPermit = mainWorkOrder.workOrderChildren?.find(x => x.containLadderPermit) !== undefined;
    const [measures, setMeasures] = useState<MeasureModel[]>([]);
    const [currentRole, setCurrentRole] = useState<Role | undefined>();
    const [nbWorker, setNbWorker] = useState<number | undefined>();
    const [commentary, setCommentary] = useState<string | undefined>();
    const [equipmentsChanging, setEquipmentsChanging] = useState<EquipmentsChanging>(new EquipmentsChanging());
    const [displayButtonNeedOnlyOneRole, setDisplayButtonNeedOnlyOneRole] = useState<boolean>(false);
    const [ladderPermitType, setLadderPermitType] = useState<StepladderType | undefined>(undefined);

    const mainRef = createRef<HTMLDivElement>();
    const wrapperRef = createRef<HTMLDivElement>();

    const [annexeCheckBoxRefs, setAnnexeCheckBoxRefs] = useState<React.RefObject<HTMLInputElement>[]>([]);

    const triggerOnClose = (errorMessage?: string) => {
        if(errorMessage){
            appTools.showMessage(MessageViewType.Warning, errorMessage);
        }

        if(mainRef.current && wrapperRef.current){
            mainRef.current!.style.animationName = "fadeOut";
            wrapperRef.current!.style.animationName = "UpToBottom";
            mainRef.current!.scrollTo({top: 0, behavior: "smooth"});
            setTimeout(() => {
                if(mainRef.current){
                    mainRef.current!.style.opacity = "0";
                }
                onClose();
            },400);
        }else{
            onClose();
        }
    }

    const getUserRole = (): Promise<Role> => {
        return new Promise((resolve, reject) => {
            let roles;
            if(declarationType !== SignatureTypeDeclaration.WORK_ACCEPTANCE){
                roles = userRoles.filter(x => x !== Role.Admin && x !== Role.Receptionnists);
            }else{
                roles = userRoles.filter(x => x !== Role.Admin);
            }

            if(roles.length === 1){
                resolve(roles[0]);
            }
            else if(roles.length > 1){
                appTools.displayRoleSelector(roles).then((role) => {
                    resolve(role);
                }).catch(() => {
                    triggerOnClose("Veuillez selectionner un rôle pour signer");
                    reject();
                });
            }else{
                triggerOnClose("Impossible de signer");
                reject();
            }
        });
    }

    //#region triggerOneDevice & triggerMultipleDevices

    const triggerOneDevice = () => {
        appTools.isLoading(true);
        declareSign().then((response) => {
            navigate(
                ONE_PHONE_SIGNATURE_ROUTE.replace(QRCODE_ROUTE, response.qrCodeValue)
                + SIGN_TYPE_FIRST_ARG_ROUTE + response.signatureType
            );
        }).catch(() => {}).finally(() => {
            appTools.isLoading(false);
            triggerOnClose();
        });
    }

    const triggerMultipleDevices = () => {
        appTools.isLoading(true);
        declareSign().then((response) => {
            navigate(
                SIGNATURE_STATE_ROUTE.replace(QRCODE_ROUTE, response.qrCodeValue) 
                + IS_CREATOR_ARG_ROUTE + "true"
                + WORK_ORDER_ARG_ROUTE + mainWorkOrder.workId.toString() 
                + SIGN_TYPE_ARG_ROUTE + response.signatureType
                );
        }).catch(() => {}).finally(() => {
            appTools.isLoading(false);
            triggerOnClose();
        });
    }

    const triggerOneRoleSignature = () => {
        appTools.isLoading(true);

        declareOneRoleSignature(currentRole!, [])
        .then(() => {
            appTools.showMessage(MessageViewType.Success, "Signature validée");
            
            if(triggerReloadWorkOrder){
                triggerReloadWorkOrder();
            }
        }).catch((err) => {
            const apiErr = err as ApiError;
            if(apiErr !== undefined && apiErr.message !== undefined){
                appTools.showMessage(MessageViewType.Error, apiErr.message);
            }
        })
        .finally(() => {
            appTools.isLoading(false);
            triggerOnClose();
        })
    }

    //#endregion

    //#region declareSign

    const declareSign = (): Promise<DeclareSignResponse> => {
        // récupérer les id des bon de travaux annexes cochés
        const workOrderChildrensChecked:number[] = [];
        for (let index = 0; index < annexeCheckBoxRefs.length; index++) {
            const ref = annexeCheckBoxRefs[index];
            if(ref.current!.checked){
                workOrderChildrensChecked.push(workOrderChildrens![index].id);
            }
        }

        if(declarationType !== SignatureTypeDeclaration.OPTIONNAL){
            return declareNormalSignature(currentRole!, workOrderChildrensChecked);
        }else{
            return declareOptionalSignature(currentRole!, workOrderChildrensChecked);
        }
    }

    const declareNormalSignature = (currentRole : Role, workOrderChildrensChecked:number[]): Promise<DeclareSignResponse> => {
        return new Promise<DeclareSignResponse>(async (resolve,reject) => {
            const measuresToSend: MeasureModel[] = [...measures];
            // si le manager a renseigné le nombre de travailleur, on l'ajoute dans les mesures
            if(nbWorker){
                measuresToSend.push({
                    workIdConcerned: mainWorkOrder.workId,
                    measureType: MeasureType.Person,
                    measureValue: nbWorker.toString()
                });
            }
            // si un commentaire a été renseigné, on l'ajoute dans les mesures
            if(commentary || ladderPermitType){
                measuresToSend.push({
                    workIdConcerned: mainWorkOrder.workId,
                    measureType: getCommentByUserRole(await getUserRole()),
                    measureValue: generateCommentary(commentary, ladderPermitType)
                });
            }

            const episChanging = equipmentsChanging.EPIsToDTO(mainWorkOrder.workId);
            const epcsChanging = equipmentsChanging.EPCsToDTO(mainWorkOrder.workId);
            
            const declareModel: DeclareSignModel = {
                roleIdUser: currentRole,
                workIdConcerned: mainWorkOrder.workId,
                workIdChildrens: workOrderChildrensChecked,
                signType: declarationType,
                measures: measuresToSend,
                newNecessaryEPIs: episChanging.episToAdd,
                newNecessaryEPCs: epcsChanging.epcsToAdd,
                necessaryEPIsToRemove: episChanging.episToRemove,
                necessaryEPCsToRemove: epcsChanging.epcsToRemove
            };

            postWithResponse<DeclareSignResponse>("/signature/declare", declareModel).then(response => {
                resolve(response);
            }).catch((err) => {
                const apiErr = err as ApiError;
                if(apiErr !== undefined){
                    appTools.showMessage(MessageViewType.Error, apiErr.message);
                }else{
                    appTools.showMessage(MessageViewType.Error, "Une erreur est survenu");
                }
                reject();
            })
        }) 
    }

    const declareOptionalSignature = (currentRole : Role, workOrderChildrensChecked:number[]): Promise<DeclareSignResponse> => {
        return new Promise<DeclareSignResponse>(async (resolve,reject) => {
            const measuresToSend: MeasureModel[] = [...measures];

            if(workOrderChildrensChecked.length === 0){
                appTools.showMessage(MessageViewType.Warning, "Aucune annexe sélectionnée");
                reject();
                return;
            }

            if(ladderPermitType){
                measuresToSend.push({
                    workIdConcerned: mainWorkOrder.workId,
                    measureType: getCommentByUserRole(await getUserRole()),
                    measureValue: generateCommentary(undefined, ladderPermitType)
                });
            }

            const episChanging = equipmentsChanging.EPIsToDTO(mainWorkOrder.workId);
            const epcsChanging = equipmentsChanging.EPCsToDTO(mainWorkOrder.workId);

            const declareModel: DeclareOptionalSignModel = {
                roleIdUser:  currentRole,
                workIdConcerned: mainWorkOrder.workId,
                workIdChildrens: workOrderChildrensChecked,
                measures: measuresToSend,
                newNecessaryEPIs: episChanging.episToAdd,
                newNecessaryEPCs: epcsChanging.epcsToAdd,
                necessaryEPIsToRemove: episChanging.episToRemove,
                necessaryEPCsToRemove: epcsChanging.epcsToRemove
            }
            postWithResponse<DeclareSignResponse>("/signature/declareOptionnal", declareModel).then(response => {
                resolve(response);
            }).catch((err) => {
                const apiErr = err as ApiError;
                if(apiErr !== undefined){
                    appTools.showMessage(MessageViewType.Error, apiErr.message);
                }else{
                    appTools.showMessage(MessageViewType.Error, "Une erreur est survenu");
                }
                reject();
            })
        }) 
    }

    const declareOneRoleSignature = (currentRole : Role, workOrderChildrensChecked:number[]): Promise<void> => {
        return new Promise<void>(async (resolve,reject) => {
            const measuresToSend: MeasureModel[] = [...measures];
            // si le manager a renseigné le nombre de travailleur, on l'ajoute dans les mesures
            if(nbWorker){
                measuresToSend.push({
                    workIdConcerned: mainWorkOrder.workId,
                    measureType: MeasureType.Person,
                    measureValue: nbWorker.toString()
                });
            }
            // si un commentaire a été renseigné, on l'ajoute dans les mesures
            if(commentary){
                measuresToSend.push({
                    workIdConcerned: mainWorkOrder.workId,
                    measureType: getCommentByUserRole(await getUserRole()),
                    measureValue: commentary
                });
            }

            const episChanging = equipmentsChanging.EPIsToDTO(mainWorkOrder.workId);
            const epcsChanging = equipmentsChanging.EPCsToDTO(mainWorkOrder.workId);
            
            const declareModel: DeclareSignModel = {
                roleIdUser: currentRole,
                workIdConcerned: mainWorkOrder.workId,
                workIdChildrens: workOrderChildrensChecked,
                signType: declarationType,
                measures: measuresToSend,
                newNecessaryEPIs: episChanging.episToAdd,
                newNecessaryEPCs: epcsChanging.epcsToAdd,
                necessaryEPIsToRemove: episChanging.episToRemove,
                necessaryEPCsToRemove: epcsChanging.epcsToRemove
            };

            post("/signature/declareAndValidate", declareModel).then(response => {
                resolve();
            }).catch((err) => {
                const apiErr = err as ApiError;
                if(apiErr !== undefined){
                    appTools.showMessage(MessageViewType.Error, apiErr.message);
                }else{
                    appTools.showMessage(MessageViewType.Error, "Une erreur est survenu");
                }
                reject();
            })
        }) 
    }

    //#endregion

    useEffect(() => {
        let getRole = true;

        // on prépare les références des checkbox afin de savoir 
        // quelles annexes sont cochés

        // il faut proposer les annexes uniquement si on déclare une demi-journée
        // déclarer une fin de demi journée ne nécessite pas de signer les annexes
        if(workOrderChildrens && declarationType === SignatureTypeDeclaration.START_HALF_DAY){
            setAnnexeCheckBoxRefs(workOrderChildrens.map(() => createRef()));
        }

        // si on est en mode optionnel, on propose de signer les annexes non signées
        if(declarationType === SignatureTypeDeclaration.OPTIONNAL){
            if(workOrderChildrens && workOrderChildrens.length !== 0){
                setAnnexeCheckBoxRefs(workOrderChildrens.map(() => createRef()));
            }else{
                // si on est en mode optionnel et qu'il n'y a pas d'annexe à signer
                // on s'arrête là, le but de la signature optionnel est de signer les annexes 
                // pas encore signées
                appTools.showMessage(MessageViewType.Warning, "Aucune annexe à signer");
                triggerOnClose();
                getRole = false;
            }
        }

        // ici on va vérifier s'il est possible de signer avec un seul rôle
        // et donc de ne pas passer par le cycle de signature
        if(declarationType === SignatureTypeDeclaration.END_HALF_DAY){
            get<boolean>(`/signature/workOrder/${mainWorkOrder.workId}/isSignatureRequiredOnlyOneRole?signType=${SignatureTypeDeclaration.END_HALF_DAY}`).then((response) => {
                setDisplayButtonNeedOnlyOneRole(response.data);
            }).catch((err) => {
                console.error(err);
            });
        }

        if(getRole){
            getUserRole().then((role) => {
                setCurrentRole(role);
            }).catch(() => {});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div ref={mainRef} className='declsign-main fill-to-screen'>
            <div className='declsign-empty-space' onClick={() => triggerOnClose()} />

            <div ref={wrapperRef} className='declsign-wrapper'>
                <div className='declsign-container'>
                    <div className='declsign-round-square' />

                    {currentRole && workOrderChildrens && workOrderChildrens.length !== 0 && annexeCheckBoxRefs.length !== 0 && (
                        <>
                            {declarationType !== SignatureTypeDeclaration.OPTIONNAL && (
                                <p className='declsign-txt'>Signer les annexes suivantes ?</p>
                            )}

                            {declarationType === SignatureTypeDeclaration.OPTIONNAL && (
                                <p className='declsign-txt'>Selectionner les annexes à signer</p>
                            )}

                            {workOrderChildrens.map((woc, index) => {
                                return (
                                    <div key={woc.id} className='declsign-checkbox-container'>
                                        <input ref={annexeCheckBoxRefs[index]} id={"decl_ckbox_" + woc.id} type='checkbox' value={woc.labelle} />
                                        <label htmlFor={"decl_ckbox_" + woc.id} >{woc.labelle}</label>
                                    </div>
                                );
                            })}

                            {declarationType === SignatureTypeDeclaration.OPTIONNAL && (
                                <p className='declsign-info'>
                                    Les annexes affichées sont uniquement les annexes 
                                    qui ne possèdent pas de signature
                                </p>
                            )}

                            <div className='declsign-separator' />
                        </>
                    )}

                    {(
                        declarationType === SignatureTypeDeclaration.START_HALF_DAY ||
                        declarationType === SignatureTypeDeclaration.OPTIONNAL
                    ) && currentRole === Role.Fireman && (
                        <>
                            {/* Les mesures et les changements d'équipements
                             peuvent être déclarer uniquement par les pompier */}
                            <EquipmentsEditButton 
                                necessaryEPCs={mainWorkOrder.necessaryEPCs}
                                necessaryEPIs={mainWorkOrder.necessaryEPIs}
                                appTools={appTools}
                                equisChanging={equipmentsChanging}
                                setEquisChanging={setEquipmentsChanging}
                                isCenterTitle={true}
                                />
                            <div className='declsign-separator' />
                            <MeasureView 
                                userRoleId={currentRole}
                                workOrders={allWorkOrder}
                                appTools={appTools}
                                measures={measures}
                                setMeasures={setMeasures}
                                isCenterTitle={true}
                                />
                            <div className='declsign-separator' />
                        </> 
                    )}
                    
                    {declarationType === SignatureTypeDeclaration.START_HALF_DAY && ((currentRole === Role.Manager && (
                            <>
                                {/** Vue réservé à l'exploitant qui doit renseigner le nombre de 
                                 * travailleur */}
                                <NbWorkerView nbWorker={nbWorker} setNbWorker={setNbWorker} appTools={appTools} />
                                <div style={{marginTop:"20px"}}/>
                                <div className='declsign-separator' />
                            </> 
                        )))
                    }

                    {(declarationType === SignatureTypeDeclaration.START_HALF_DAY || 
                        declarationType === SignatureTypeDeclaration.OPTIONNAL
                    ) && currentRole === Role.Executor 
                    && containLadderPermit && (
                        <>
                            {/** Vue réservé au GIES 2 à la présence d'un permis échelle/escabeau */}
                            <ButtonLadderPermit appTools={appTools} style={{marginBlock: "1em"}}
                                ladderPermitType={ladderPermitType} setLadderPermitType={setLadderPermitType} />
                        </>
                    )}

                    <CommentaryView 
                        isCenterTitle={true}
                        commentary={commentary} 
                        setCommentary={setCommentary} 
                        appTools={appTools} />
                    <div style={{marginTop:"20px"}}/>
                    <div className='declsign-separator' />

                    {!displayButtonNeedOnlyOneRole ? (
                        <>
                            <p className='declsign-txt'>Combien d'appareil possédez vous ?</p>

                            <div className='declsign-container-buttons'>
                                <div className='declsign-big-button' onClick={() => {triggerOneDevice()}}>
                                    <p>Un appareil</p>
                                    <img alt='Signature avec un téléphone' src={OnePhoneImg} />
                                </div>
                                <div className='declsign-big-button' onClick={() => {triggerMultipleDevices()}}>
                                    <p>Plusieurs appareils</p>
                                    <img alt='Signature avec plusieurs téléphones' src={MultiplePhoneImg} />
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className='declsign-one-big-button' onClick={triggerOneRoleSignature}>
                            <p>Signer maintenant</p>
                            <img alt='Signer maintenant' src={OneShotSignatureImg} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default DeclareSignature;