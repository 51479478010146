import React, { createRef } from "react";
import './EquipmentsDetail.css';
import { EPIResponse } from "../../api/responses";
import { GoBack } from "..";
import { EpiAcidBootsImg, EpiAcidSuitImg, EpiAirDuctHoodImg, EpiAirDuctSuitImg, EpiCapImg, EpiCutGlovesImg, EpiDustMaskImg, EpiDustSuitImg, EpiFaceGlassImg, EpiGogglesImg, EpiHarnessImg, EpiHearingProtectionImg, EpiHelmetImg, EpiLeatherHandlingGlovesImg, EpiNeopreneGlovesImg, EpiPanoramicMaskImg, EpiSafetyGlassesImg, EpiSafetyShoesImg, EpiWeldingHoodVentilatedImg, EpiWeldingMaskImg, EquiDisposalImg, PersonImg } from "../../images";
import { EpiEnum } from "../../types.ts";

type EquipmentsDetailProps = {
    workOrderId: number,
    epis: EPIResponse[],
    onClose: () => void
};

const EquipmentsDetail = (props: EquipmentsDetailProps) => {
    const {workOrderId, epis, onClose} = props;
    const mainRef = createRef<HTMLDivElement>();

    const [episHead, episBody, episHands, episLegs, episFeet] = [
        epis.filter(epi => epi.epiIsHead), 
        epis.filter(epi => epi.epiIsBody), 
        epis.filter(epi => epi.epiIsHands), 
        epis.filter(epi => epi.epiIsLegs), 
        epis.filter(epi => epi.epiIsFeet)];

    const triggerHideDetail = () => {
        mainRef.current!.style.animationName = "slideout";
        setTimeout(() => {
            if(mainRef.current){
                mainRef.current!.style.opacity = "0";
            }
            onClose();
        },400);
    }

    const getIllustrationEPI = (epi : EPIResponse) : string | null => {
        switch(epi.epiId){
            case EpiEnum.AcidBoots:
                return EpiAcidBootsImg;
            case EpiEnum.AcidSuit:
                return EpiAcidSuitImg;
            case EpiEnum.AirDuctHood:
                return EpiAirDuctHoodImg;
            case EpiEnum.AirDuctSuit:
                return EpiAirDuctSuitImg;
            case EpiEnum.Cap:
                return EpiCapImg;
            case EpiEnum.CutGloves:
                return EpiCutGlovesImg;
            case EpiEnum.DustMask:
                return EpiDustMaskImg;
            case EpiEnum.DustSuit:
                return EpiDustSuitImg;
            case EpiEnum.FaceGlass:
                return EpiFaceGlassImg;
            case EpiEnum.SecurityGoggles:
                return EpiGogglesImg;
            case EpiEnum.Harness:
                return EpiHarnessImg;
            case EpiEnum.HearingProtection:
                return EpiHearingProtectionImg;
            case EpiEnum.Helmet:
                return EpiHelmetImg;
            case EpiEnum.LeatherHandlingGloves:
                return EpiLeatherHandlingGlovesImg;
            case EpiEnum.NeopreneGloves:
                return EpiNeopreneGlovesImg;
            case EpiEnum.PanoramicMask:
                return EpiPanoramicMaskImg;
            case EpiEnum.SafetyGlasses:
                return EpiSafetyGlassesImg;
            case EpiEnum.SafetyShoes:
                return EpiSafetyShoesImg;
            case EpiEnum.WeldingHoodVentilated:
                return EpiWeldingHoodVentilatedImg;
            case EpiEnum.WeldingMask:
                return EpiWeldingMaskImg;
        }
        return null;
    }

    return (
        <div ref={mainRef} className="equidetail-main">
            <GoBack onGoBack={triggerHideDetail} />
            <div className="equidetail-header">
                <h1 className="equidetail-title">Liste EPI</h1>
                <p className="equidetail-wonumber">N° Permis : {workOrderId}</p>
            </div>
            <div className="equidetail-content">
                <img alt="" className="equidetail-img" src={PersonImg} />
                {epis.length !== 0 && epis.map((epi, index) => {
                    // affiche les illustrations des EPI
                    const imgEpi = getIllustrationEPI(epi);
                    if(imgEpi){
                        if(imgEpi){ 
                            const nbPlacement = 
                                [epi.epiIsHead, epi.epiIsBody, epi.epiIsHands, epi.epiIsLegs, epi.epiIsFeet]
                                .filter(placement => placement)
                                .length;
                            return (<img key={index} alt="" className="equidetail-img" src={imgEpi} style={{zIndex: nbPlacement > 1 ? "0" : "10"}} />);
                        }
                        return (<img key={index} alt="" className="equidetail-img" src={imgEpi} style={{zIndex: "100"}} />);
                    }
                    return <></>
                })}
                <img className="equidetail-img" src={EquiDisposalImg} alt="" />
                <div className="equidetail-epi-container equidetail-head">
                    {episHead.length !== 0 && episHead.map((epi, index) => {
                        return <p key={index}>{epi.epiName}</p>
                    })}
                </div>
                <div className="equidetail-epi-container equidetail-body">
                    {episBody.length !== 0 && episBody.map((epi, index) => {
                        return <p key={index}>{epi.epiName}</p>
                    })}
                </div>
                <div className="equidetail-epi-container equidetail-hands">
                    {episHands.length !== 0 && episHands.map((epi, index) => {
                        return <p key={index}>{epi.epiName}</p>
                    })}
                </div>
                <div className="equidetail-epi-container equidetail-legs">
                    {episLegs.length !== 0 && episLegs.map((epi, index) => {
                        return <p key={index}>{epi.epiName}</p>
                    })}
                </div>
                <div className="equidetail-epi-container equidetail-feet">
                    {episFeet.length !== 0 && episFeet.map((epi, index) => {
                        return <p key={index}>{epi.epiName}</p>
                    })}
                </div>
            </div>
        </div>
    );
};

export default EquipmentsDetail;