import React, { CSSProperties, useState } from "react";
import './InfoDetails.css';
import { ChevronDownImg } from "../../images";

type InfoDetailsProps = {
    title:string,
    infos: string[]
    customClass?: string,
    customStyle?: CSSProperties,
}

const InfoDetails = (props: InfoDetailsProps) => {
    const { title, infos, customClass, customStyle } = props;
    const [isOpened, setIsOpenned] = useState<boolean>(false);

    return (<div className={"petra-button infodetails-main " + customClass} style={customStyle} onClick={() => {setIsOpenned(prev => !prev)}}>
        <div className="infodetails-header">
            <img alt="Chevron" src={ChevronDownImg} style={!isOpened ? {transform: "rotate(-90deg)"} : {}} />
            <p className="infodetails-title">
                {title}
            </p>
        </div>
        {isOpened && (
             <div className="infodetails-info-container">
                {infos.map((info, index) => (
                    <p key={index} className="infodetails-info">{info}</p>
                ))}
            </div>
        )}
    </div>);
}

export default InfoDetails;