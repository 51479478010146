import React, { createRef, CSSProperties, useCallback, useEffect, useState } from "react";
import './Stepper.css';
import { BLUE_PRIMARY } from "../../const.ts";
import Keyframes from "../Keyframes/Keyframes.tsx";

type StepperProps = {
    currentStep: number,
    items: StepItem[]
}

export type StepItem = {
    title: string,
    content: JSX.Element
}

const animationSlideMillisec = 1050;
const animationNameLineBlue = "KF_lineBlue";
const animationNameLineGray = "KF_lineGray";

const marginLeftStepperOutsideLeft = "-150%";
const marginLeftStepperLeft = "-75%";
const marginLeftStepperMiddle = "0%";
const marginLeftStepperRight = "75%";
const marginLeftStepperOutsideRight = "150%";

const buildStepper = (title: string, step: number, refToApply: React.RefObject<HTMLDivElement>) => {
    return (
    <div ref={refToApply} key={step} className="stepper-step-container">
        <div className="stepper-step-circle">{step}</div>
        <p className="stepper-step-title">{title}</p>
    </div>);
}

const Stepper = (props: StepperProps) => {
    const {currentStep, items} = props;
    if(currentStep < 1 || currentStep > items.length){
        throw new Error("Invalid current step");
    }
    
    const [visualStep, setVisualStep] = useState<number>(1);
    const [isViewInit, setIsViewInit] = useState<boolean>(false);
    const [triggerReset, setTriggerReset] = useState<boolean>(false);
    const [steppersRef, setSteppersRef] = useState<React.RefObject<HTMLDivElement>[]>([]);
    const [steppersView, setSteppersView] = useState<React.JSX.Element[]>([]);
    const [contentViewOutside, setContentViewOutside] = useState<React.JSX.Element | undefined>();
    const [lineGrayKF, setLineGrayKF] = useState<{from: CSSProperties, to: CSSProperties}>({from: {}, to: {}});
    const [lineBlueKF, setLineBlueKF] = useState<{from: CSSProperties, to: CSSProperties}>({from: {}, to: {}});

    const lineStepperBlue = createRef<HTMLDivElement>();
    const lineStepperGray = createRef<HTMLDivElement>();
    const divContent = createRef<HTMLDivElement>();
    const divContentOutside = createRef<HTMLDivElement>();

    useEffect(() => {
        const arrayRef : React.RefObject<HTMLDivElement>[] = [];
        const arrayView : React.JSX.Element[] = [];

        items.forEach((item, index) => {
            const refStepper = createRef<HTMLDivElement>();
            const viewStepper = buildStepper(item.title, index + 1, refStepper);

            arrayRef.push(refStepper);
            arrayView.push(viewStepper);
        });

        setSteppersRef(arrayRef);
        setSteppersView(arrayView);
    }, [items])

    useEffect(() => {
        if(steppersRef.length === steppersView.length && steppersView.length > 0){
            arrangeSteppers(visualStep);
            arrangeLine(visualStep);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [steppersRef, steppersView])

    useEffect(() => {
        if(currentStep !== visualStep){
            if(isViewInit){
                if(currentStep > visualStep){
                    startAnimationIncrease(currentStep);
                }else{
                    // TODO : animation go back
                }
                arrangeSteppers(currentStep);
                arrangeLine(currentStep);
            }
            setVisualStep(currentStep);
        }
        setIsViewInit(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep, setVisualStep, visualStep]);

    const startAnimationIncrease = (newCurrentStep: number) => {
        setContentViewOutside(items[newCurrentStep - 2].content);

        divContent.current!.style.opacity = "0";
        divContent.current!.style.animationDelay = animationSlideMillisec/2 + "ms";
        divContent.current!.style.animationDuration = animationSlideMillisec/2 + "ms";
        divContent.current!.style.pointerEvents = "none";
        divContentOutside.current!.style.right = "0";
        divContentOutside.current!.style.left = "unset";
        divContentOutside.current!.style.animationDuration = animationSlideMillisec/2  + "ms";

        divContent.current!.style.animationName = "slideRightToCenter";
        divContentOutside.current!.style.animationName = "slideCenterToLeft";

        lineStepperBlue.current!.style.animationDuration = animationSlideMillisec + "ms";
        lineStepperGray.current!.style.animationDuration = animationSlideMillisec + "ms";
        lineStepperBlue.current!.style.animationName = animationNameLineBlue;
        lineStepperGray.current!.style.animationName = animationNameLineGray;
        lineStepperBlue.current!.style.opacity = "0";
        lineStepperGray.current!.style.opacity = "0";

        setLineBlueKF({
            from: {marginLeft: getMarginLineBlue(newCurrentStep-1), opacity: 1},
            to: {marginLeft: getMarginLineBlue(newCurrentStep), opacity: 1}
        })
        setLineGrayKF({
            from: {marginRight: getMarginLineGray(newCurrentStep-1), opacity: 1},
            to: {marginRight: getMarginLineGray(newCurrentStep), opacity: 1}
        })

        const newCurrentIndex = newCurrentStep - 1;

        steppersRef.forEach((refStep, index) => {
            refStep.current!.style.animationDuration = animationSlideMillisec + "ms";
            if(index === newCurrentIndex - 2){
                refStep.current!.style.animationName = "stepper-left-to-outsideleft";
            }else if(index === newCurrentIndex - 1 ){
                refStep.current!.style.animationName = "stepper-middle-to-left";
            }else if(index === newCurrentIndex){
                refStep.current!.style.animationName = "stepper-right-to-middle";
                const circle = (refStep.current!.children[0] as HTMLDivElement);
                circle.style.animationDuration = animationSlideMillisec + "ms";
                circle.style.animationName = "normal-to-active";
            }else if(index === newCurrentIndex + 1 ){
                refStep.current!.style.animationName = "stepper-outsideright-to-right";
            }
        });

        setTimeout(() => {
            setTriggerReset(prev => !prev);
        }, animationSlideMillisec-50)
    }

    const reset = () => {
        setContentViewOutside(undefined);
        divContent.current!.style.pointerEvents = "unset";
        divContent.current!.style.animationName = "";
        divContent.current!.style.opacity = "1";
        divContentOutside.current!.style.animationName = "";
        divContentOutside.current!.style.opacity = "0";
        lineStepperBlue.current!.style.animationName = "";
        lineStepperGray.current!.style.animationName = "";
        lineStepperBlue.current!.style.opacity = "1";
        lineStepperGray.current!.style.opacity = "1";

        steppersRef.forEach((stepper) => {
            stepper.current!.style.animationName = "";
        })

    }

    useEffect(() => {
        if(triggerReset){
            reset();
            setTriggerReset(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerReset, setTriggerReset])

    const getMarginLeftStepper = (currentIndex: number, indexStepper: number): string => {
        if(indexStepper < currentIndex - 1 ){
            return marginLeftStepperOutsideLeft;
        }else if(indexStepper === currentIndex - 1 ){
            return marginLeftStepperLeft;
        }else if(indexStepper === currentIndex){
            return marginLeftStepperMiddle;
        }else if(indexStepper === currentIndex + 1 ){
            return marginLeftStepperRight;
        }else{
            return marginLeftStepperOutsideRight;
        }
    }

    const arrangeSteppers = useCallback((currentVisualStep: number) => {
        const addClassToCirleIfNotExist = (div: HTMLDivElement, className: string) => {
            if(!div.children[0].classList.contains(className)){
                div.children[0].classList.add(className);
            }
        }

        const removeClassToCircleIfExist = (div: HTMLDivElement, className: string) => {
            if(div.children[0].classList.contains(className)){
                div.children[0].classList.remove(className);
            }
        }

        const currentIndex = currentVisualStep - 1;
        steppersRef.forEach((refStep, index) => {
            refStep.current!.style.marginLeft = getMarginLeftStepper(currentIndex, index);
            if(index <= currentIndex){
                addClassToCirleIfNotExist(refStep.current!, "stepper-step-circle-active");
            }else{
                removeClassToCircleIfExist(refStep.current!, "stepper-step-circle-active");
            }
        })
    }, [steppersRef])

    const getMarginLineBlue = (currentVisualStep: number): string => {
        if(currentVisualStep - 1 > 1){
            return "-25%";
        }else if(currentVisualStep - 1 === 1){
            return "12.5%";
        }else{
            return "50%";
        }
    }

    const getMarginLineGray = useCallback((currentVisualStep: number): string => {
        if(steppersRef.length === currentVisualStep){
            return "50%";
        }else if(steppersRef.length === currentVisualStep + 1){
            return "12.5%";
        }else{
            return "-25%";
        }
    },[steppersRef.length])

    const arrangeLine = useCallback((currentVisualStep: number) => {
        lineStepperBlue.current!.style.marginRight = "50%";
        lineStepperGray.current!.style.marginLeft = "50%";

        lineStepperBlue.current!.style.marginLeft = getMarginLineBlue(currentVisualStep);
        lineStepperGray.current!.style.marginRight = getMarginLineGray(currentVisualStep);
        
    }, [getMarginLineGray, lineStepperBlue, lineStepperGray])

    return (
    <div className="stepper-main">
        <div className="stepper-header">
            <div ref={lineStepperBlue} className="stepper-line" style={{backgroundColor: BLUE_PRIMARY}} />
            <div ref={lineStepperGray} className="stepper-line" />
            {steppersView}
        </div>
        <div className="stepper-body">
            <div ref={divContent} className="stepper-content">
                {items[visualStep - 1].content}
            </div>
            <div ref={divContentOutside} className="stepper-content-outside">
                {contentViewOutside}
            </div>
        </div>

        <Keyframes name={animationNameLineBlue} from={lineBlueKF.from} to={lineBlueKF.to} />
        <Keyframes name={animationNameLineGray} from={lineGrayKF.from} to={lineGrayKF.to} />

        <Keyframes name="stepper-outsideright-to-right" 
            from={{marginLeft: marginLeftStepperOutsideRight}} to={{marginLeft: marginLeftStepperRight}} />
        <Keyframes name="stepper-right-to-middle" 
            from={{marginLeft: marginLeftStepperRight}} to={{marginLeft: marginLeftStepperMiddle}} />
        <Keyframes name="normal-to-active" 
            from={{
                backgroundColor: 'white',
                borderColor: '#5ACAF2',
                color: '#5ACAF2'
            }} 
            _80={{
                backgroundColor: 'white',
                borderColor: '#5ACAF2',
                color: '#5ACAF2'
            }}
            to={{
                backgroundColor: '#5ACAF2',
                borderColor: '#5ACAF2',
                color: 'white',
            }}/>
        <Keyframes name="stepper-middle-to-left" 
            from={{marginLeft: marginLeftStepperMiddle}} to={{marginLeft: marginLeftStepperLeft}} />
        <Keyframes name="stepper-left-to-outsideleft" 
            from={{marginLeft: marginLeftStepperLeft}} to={{marginLeft: marginLeftStepperOutsideLeft}} />
    </div>);
}

export default Stepper;