import React, { useCallback, useEffect, useState } from 'react';
import './WorkOrders.css';
import { Badges, GoBack, LoadingViewSmall, WOElement } from '../views';
import { useNavigate } from 'react-router-dom';
import { ROOT_ROUTE } from '../route.ts';
import { useRequest, useWorkOrder } from '../hooks';
import { BASE_URL, BLACK, ORANGE_PRIMARY, WHITE } from '../const.ts';
import { AppTools, MessageViewType, Role, WorkOrderState } from '../types.ts';
import { UserInfoResponse, WorkOrderResponse } from '../api/responses.ts';
import { IconButton, IntersectView, SearchImg } from 'petrasign-packages';

type WorkOrdersProps = {
    appTools: AppTools
}

const WorkOrders = (props: WorkOrdersProps) => {
    const {appTools} = props;
    const navigate = useNavigate();
    const [, , get] = useRequest(BASE_URL, props.appTools);

    const { workOrders, hasNext, getNext, containFilters, isLoading, displayContainerSearch } = useWorkOrder(appTools);

    const [workOrdersNotStarted, setWorkOrdersNotStarted] = useState<WorkOrderResponse[] | undefined>();
    const [workOrdersStarted, setWorkOrdersStarted] = useState<WorkOrderResponse[] | undefined>();
    const [workOrderFinished, setWorkOrderFinished] = useState<WorkOrderResponse[] | undefined>();

    const [userRole, setUserRole] = useState<Role| undefined>();

    const getUserRole = (roles: Role[])=> {
        roles = roles.filter(x => x !== Role.Admin);
        if(roles.length === 1){
            setUserRole(roles[0])
        }
        else if(roles.length > 1){
            appTools.displayRoleSelector(roles).then((role) => {
                setUserRole(role);
            }).catch(() => {
                appTools.showMessage(MessageViewType.Error, "Veuillez selectionner un rôle pour continuer");
            });
        }else{
            appTools.showMessage(MessageViewType.Error, "Vous n'avez pas de rôle attribué");
        }
    }

    useEffect(() => {
        get<UserInfoResponse>("/api/user/getCurrentInfo").then((response) => {
            getUserRole(response.data.userRoleIds);
        }).catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(workOrders){
            setWorkOrdersStarted(workOrders.filter(x => 
                x.workState !== WorkOrderState.AwaitingDecision && 
                x.workState !== WorkOrderState.WorksApproved));
            setWorkOrdersNotStarted(workOrders.filter(x => x.workState === WorkOrderState.AwaitingDecision));
            setWorkOrderFinished(workOrders.filter(x => x.workState === WorkOrderState.WorksApproved));
        }
    }, [workOrders])

    const triggerIsAtTheEnd = useCallback((isAtTheEnd: boolean) => {
        if(workOrders && hasNext && isAtTheEnd){
            getNext();
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workOrders, hasNext]);

    return (
        <div className='worders-main fill-to-screen' >
            <div className='wordres-bg fill-to-screen' />

            <GoBack onGoBack={() => {navigate(ROOT_ROUTE)}} />

            <div className='worders-container-button'>
                <IconButton cssText={{color: BLACK}} text='Rechercher / Filtrer' iconSrc={SearchImg} onClick={displayContainerSearch}
                    cssButton={{marginInline: '2em', marginTop: '1em', backgroundColor: WHITE}} />
                {containFilters && (
                    <Badges text='Résultat filtré' backgroundColor={ORANGE_PRIMARY} color='white' styleText={{fontWeight: 'bold'}} />
                )}
            </div>
           
            <div className='worders-wrapper'>
                <div className='worders-container'>

                    {userRole && workOrdersStarted && workOrdersStarted.length === 0 && 
                      workOrdersNotStarted && workOrdersNotStarted.length === 0 && (
                        <p className='worders-txt'>Aucun bon</p>
                    )}

                    {/* Affichage de la page par défaut */}
                    {userRole && workOrdersStarted && workOrdersStarted.length !== 0 && (
                        <>
                            <h1 className='worders-section'>En cours</h1>
                            <div className='worders-separator' />
                        </>
                    )}
                    {userRole && workOrdersStarted && workOrdersStarted.map(wo => {
                        return (
                            <WOElement key={wo.workId} userRole={userRole} workOrder={wo} />
                        );
                    })}

                    {userRole && workOrdersNotStarted && workOrdersNotStarted.length !== 0 && (
                        <>
                            <h1 className='worders-section'>Bon concernés</h1>
                            <div className='worders-separator' />
                        </>
                    )}
                    {userRole && workOrdersNotStarted && workOrdersNotStarted.map(wo => {
                        return (
                            <WOElement key={wo.workId} userRole={userRole} workOrder={wo} />
                        );
                    })}

                    {userRole && workOrderFinished && workOrderFinished.length !== 0 && (
                        <>
                            <h1 className='worders-section'>Bon terminés</h1>
                            <div className='worders-separator' />
                        </>
                    )}
                    {userRole && workOrderFinished && workOrderFinished.map(wo => {
                        return (
                            <WOElement key={wo.workId} userRole={userRole} workOrder={wo} />
                        );
                    })}
                    {userRole && workOrders && (hasNext || isLoading) && (
                        <IntersectView setIsIntersecting={triggerIsAtTheEnd}>
                            <div className='worders-more-container'>
                                <LoadingViewSmall />
                            </div>
                        </IntersectView>
                    )}
                </div>
            </div>
        </div>
    );
}

export default WorkOrders;