interface Config {
    apiBaseUrl: string;
    appNameDomain: string;
    petraFrontUrl: string;
}

const config: Config = {
    apiBaseUrl: "https://api.sign.sis-petra.fr",
    appNameDomain: "sis-petra.fr",
    petraFrontUrl: "https://form.sis-petra.fr",
};

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
    console.log('development');
    config.apiBaseUrl = "https://localhost:7137";
    config.appNameDomain = "localhost:3001";
    config.petraFrontUrl = "http://localhost:3400";
} else {
    // production code
    console.log('production');
    config.apiBaseUrl = "https://api.sign.sis-petra.fr";
    config.appNameDomain = "sis-petra.fr";
    config.petraFrontUrl = "https://form.sis-petra.fr";
}

export default config;