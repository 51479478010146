import React, { useState } from "react";
import { ModalTemplate } from "..";
import { AppTools } from "../../types";
import { IconButton, IconCheckBoxes, MultipleSelectCombobox, StandardComboboxAutoComplete, StandardEntry, Switch } from "petrasign-packages";
import { AwaitingSignatureBlackImg, AwaitingSignatureWhiteImg, WipBlackImg, WipWhiteImg, UnfinishedWorkBlackImg, UnfinishedWorkWhiteImg, CheckBlackImg, CheckWhiteImg, TrashWhiteImg } from "../../images";
import './WorkOrderFilters.css';
import { RED_PRIMARY } from "../../const.ts";

type WorkOrderFiltersProps = {
    appTools: AppTools,

    defaultWorkOrderNameSearch: string,
    defaultWorkOrderNumberSearch: string,
    defaultWorkOrderOTNumberSearch: string,
    defaultBuildingsSearch: number[],
    defaultWorkOrderTypesSearch: number[],
    defaultWorkOrderStatesSearch: boolean[],
    defaultSelfSignedWorkOrderSearch: boolean,
    defaultWorkOrderCompanyAffiliedSearch: number | undefined,

    buildingOptions: {labelle?:string, value:any}[],
    workOrderTypesOptions: {labelle?:string, value:any}[],
    companiesOptions: {labelle?:string, value:any}[],

    onValidateFilters?: (
        workOrderNameSearch: string,
        workOrderNumberSearch: string,
        workOrderOTNumberSearch: string,
        buildingsSearch: number[],
        workOrderTypesSearch: number[],
        workOrderStatesSearch: boolean[],
        selfSignedWorkOrderSearch: boolean,
        workOrderCompanyAffiliedSearch: number | undefined
    ) => void
}

const WorkOrderFilters = (props: WorkOrderFiltersProps) => {
    const { 
        appTools, 
        defaultWorkOrderNameSearch,
        defaultBuildingsSearch,
        defaultSelfSignedWorkOrderSearch,
        defaultWorkOrderCompanyAffiliedSearch,
        defaultWorkOrderNumberSearch,
        defaultWorkOrderOTNumberSearch,
        defaultWorkOrderStatesSearch,
        defaultWorkOrderTypesSearch,

        buildingOptions,
        workOrderTypesOptions,
        companiesOptions,
        onValidateFilters
    } = props;

    const [workOrderNameSearch, setWorkOrderNameSearch] = useState<string>(defaultWorkOrderNameSearch);
    const [workOrderNumberSearch, setWorkOrderNumberSearch] = useState<string>(defaultWorkOrderNumberSearch);
    const [workOrderOTNumberSearch, setWorkOrderOTNumberSearch] = useState<string>(defaultWorkOrderOTNumberSearch);
    const [buildingsSearch, setBuildingsSearch] = useState<number[]>(defaultBuildingsSearch);
    const [workOrderTypesSearch, setWorkOrderTypesSearch] = useState<number[]>(defaultWorkOrderTypesSearch);
    const [workOrderStatesSearch, setWorkOrderStatesSearch] = useState<boolean[]>(defaultWorkOrderStatesSearch);
    const [selfSignedWorkOrderSearch, setSelfSignedWorkOrderSearch] = useState<boolean>(defaultSelfSignedWorkOrderSearch);
    const [workOrderCompanyAffiliedSearch, setWorkOrderCompanyAffiliedSearch] = useState<number | undefined>(defaultWorkOrderCompanyAffiliedSearch);

    /** 
     * Déclenche le changement de bâtiment selectionné 
     * 
     * ℹ️ *Cette fonction va aussi vérifier si l'utilisateur a ajouter un nouveau bâtiment, et dans
     * ce cas, on va ajouter les bâtiments ayant le même nom.*
     * 
     * 💬 *C'est une demande des chargés de travaux car quand il ajoute (par exemple) le 209, il souhaite
     * avoir le 209b, 209c... etc*
     * */
    const triggerSearchBuildings = (buildIds : number[]) => {

        // si l'utilsateur rajoute un bâtiment
        if(buildIds.length > buildingsSearch.length){
            // on récupère l'id du bâtiment qui a été rajouté
            const buildingFind = buildIds.filter((b) => !buildingsSearch.includes(b))
            if(buildingFind.length === 1 && buildingOptions) {
                // on récupère le nom du bâtiment en question
                const findedBuilding = buildingOptions.find((buiOpt) => buiOpt.value === buildingFind[0])
                if(findedBuilding && findedBuilding.labelle){
                    // puis on recherche d'autres bâtiments ayant des noms similaire
                    const buildingsWithSimilarName = buildingOptions.filter((buiOpt) => 
                        buiOpt.labelle && 
                        buiOpt.labelle.includes(findedBuilding.labelle!) &&
                        !buildIds.includes(buiOpt.value));

                    // si on en trouve on les rajoute
                    if(buildingsWithSimilarName.length > 0){
                        buildIds = buildIds.concat(buildingsWithSimilarName.map(bui => bui.value));
                    }
                }
            }
        }
        

        setBuildingsSearch(buildIds);
    }

    const triggerValidate = () => {
        onValidateFilters && onValidateFilters(
            workOrderNameSearch,
            workOrderNumberSearch,
            workOrderOTNumberSearch,
            buildingsSearch,
            workOrderTypesSearch,
            workOrderStatesSearch,
            selfSignedWorkOrderSearch,
            workOrderCompanyAffiliedSearch
        );
    }

    const triggerClear = () => {
        setWorkOrderNameSearch('');
        setWorkOrderNumberSearch('');
        setWorkOrderOTNumberSearch('');
        setBuildingsSearch([]);
        setWorkOrderTypesSearch([]);
        setWorkOrderStatesSearch([false, false, false, false]);
        setSelfSignedWorkOrderSearch(false);
        setWorkOrderCompanyAffiliedSearch(undefined);
    }

    return (
        <ModalTemplate appTools={appTools} onGoBack={triggerValidate}>
            <div className="work-order-filters-main">
                <StandardEntry title="Recherche par nom de bon" placeholder="Recherche" 
                        value={workOrderNameSearch} setValue={setWorkOrderNameSearch} />
                    
                <StandardEntry title="Recherche par numéro de l'OT" placeholder="Recherche" 
                    value={workOrderOTNumberSearch} setValue={setWorkOrderOTNumberSearch} />

                <StandardEntry title="Recherche par numéro de bon" placeholder="Recherche"
                    value={workOrderNumberSearch} setValue={setWorkOrderNumberSearch} />
                
                <MultipleSelectCombobox title="Bâtiment" placeholder="Sélectionner un bâtiment"
                    options={buildingOptions} values={buildingsSearch} setValues={triggerSearchBuildings} />
                
                <MultipleSelectCombobox title="Type de permis présent" placeholder="Sélectionner un type de permis"
                    options={workOrderTypesOptions} values={workOrderTypesSearch} setValues={setWorkOrderTypesSearch} />
                
                <StandardComboboxAutoComplete title="Entreprise affiliée" placeholder="Sélectionner une entreprise"
                    options={companiesOptions} selectUndefined={() => setWorkOrderCompanyAffiliedSearch(undefined)}
                    value={workOrderCompanyAffiliedSearch} setValue={setWorkOrderCompanyAffiliedSearch} />

                <IconCheckBoxes 
                    title="État des bons"
                    values={workOrderStatesSearch} setValues={setWorkOrderStatesSearch}
                    icons={[
                        {iconBlack: AwaitingSignatureBlackImg, iconWhite: AwaitingSignatureWhiteImg},
                        {iconBlack: WipBlackImg, iconWhite: WipWhiteImg},
                        {iconBlack: UnfinishedWorkBlackImg, iconWhite: UnfinishedWorkWhiteImg},
                        {iconBlack: CheckBlackImg, iconWhite: CheckWhiteImg}
                    ]}
                    />
                
                <Switch title="Bons signés par vous" value={selfSignedWorkOrderSearch} setValue={setSelfSignedWorkOrderSearch} />
            
                <IconButton iconSrc={TrashWhiteImg} text="Retirer tout les filtres" onClick={triggerClear} 
                    cssButton={{backgroundColor: RED_PRIMARY, marginBlock: "1em"}} />
            </div>
        </ModalTemplate>
    )
}

export default WorkOrderFilters;