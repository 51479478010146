import React, { createRef, useEffect, useState } from "react";
import './StepladderPermit.css';
import { CrossRedImg, LadderImg, StepladderImg } from "../../images";
import { AppTools, StepladderType } from "../../types";
import { GoBack } from "../../views";
import { Stepper } from "../../components";
import { BLUE_PRIMARY, RED_PRIMARY } from "../../const.ts";

type StepladderPermitProps = {
    appTools: AppTools,
    validateLadder: (type: StepladderType) => void
}

type QuestionStepper = {
    title: string,
    description: string,
}

const ladderQuestions: QuestionStepper[] = [
    {
        title: "Étiquette", 
        description: "Étiquette d'avertissement en place, non endommagée et lisible."
    },
    {
        title: "État général", 
        description: "L'échelle est exempte de tout matériaux glissant et de toute projection susceptible "+
                     "d'entraver son fonctionnement (peinture, colle, rouille, graisse)."
    },
    {
        title: "Échelons et montants", 
        description: "Pas de déformation, d'impact, d'extrémités tordues, aucun échelon ne doit bouger."
    },
    {
        title: "Patins anti-dérapants", 
        description: "Présence et bonne fixation. Présence de stries-antidérapantes."
    },
];

const stepladderQuestions: QuestionStepper[] = [
    {
        title: "Étiquette", 
        description: "Étiquette d'avertissement en place, non endommagée et lisible."
    },
    {
        title: "Stabilité", 
        description: "L'équipement doit être stable : pas de charnière lâche."
    },
    {
        title: "État général", 
        description: "L'escabeau est exempt de tout materiau glissant et de toute projection susceptible "+
                     "d'entraver son fonctionnement (peinture, colle, rouille, graisse)."
    },
    {
        title: "Marches et montants", 
        description: "Pas de déformation, d'impact, d'extrémités tordues, aucune "+
                     "marche ne doit bouger."
    },
    {
        title: "Plateforme", 
        description: "Bon état général de la plateforme et de ses systèmes de fixation"
    },
    {
        title: "Patins anti-dérapants", 
        description: "Présence et bonne fixation. Présence de stries anti-dérapantes."
    },
    {
        title: "Système anti-séparation", 
        description: "Présence d'un dispositif d'ouverture/fermeture en bon état (charnière, sangles en "+
                     "nylon ou autre dispositif d'ouverture/fermeture)."
    },
]

const buildQuestionsSteppers = (description: string, isYes: () => void, isNo: () => void) => {
    return (
    <div className="stepladder-question-main">
        <p className="stepladder-question-txt">{description}</p>
        <div className="stepladder-question-button-container">
            <button className="petra-button stepladder-question-button" style={{backgroundColor: BLUE_PRIMARY}} onClick={isYes}>OUI</button>
            <button className="petra-button stepladder-question-button" style={{backgroundColor: RED_PRIMARY}} onClick={isNo}>NON</button>
        </div>
    </div>)
}

const StepladderPermit = (props: StepladderPermitProps) => {
    const {appTools, validateLadder} = props;

    const mainRef = createRef<HTMLDivElement>();
    const stepladderContentRef = createRef<HTMLDivElement>();
    const stepladderStepContainerRef = createRef<HTMLDivElement>();
    const finishButtonRef = createRef<HTMLButtonElement>();
    const redCrossRef = createRef<HTMLImageElement>();

    const [step, setStep] = useState<number>(1);
    const [typeLadder, setTypeLadder] = useState<StepladderType | undefined>(undefined);
    const [isSuccess, setIsSuccess] = useState<boolean | undefined>(undefined);
    
    const triggerHide = (validateTypeLadder?: 'ladder' | 'stepladder') => {
        mainRef.current!.style.animationName = "showOut";
        setTimeout(() => {
            if(mainRef.current){
                mainRef.current!.style.opacity = "0";
            }
            appTools.popDisplay();
            if(validateTypeLadder !== undefined){
                validateLadder(validateTypeLadder);
            }
        },400);
    }

    const triggerSetTypeLadder = (typeLadder: 'ladder' | 'stepladder') => {
        stepladderContentRef.current!.style.animationName = "showOut";
        setTimeout(() => {
            setTypeLadder(typeLadder);
        },400);
    }

    const triggerYes = () => {
        const lnQuestions = (typeLadder === 'ladder' ? ladderQuestions.length: stepladderQuestions.length);
        if(step === lnQuestions){
            triggerFinishQuestions(true);
        }else{
            setStep(prev => prev + 1);
        }
    }

    const triggerFinishQuestions = (isSuccess: boolean) => {
        stepladderStepContainerRef.current!.style.animationName = "fadeOutToTop";
        setTimeout(() => {
            setIsSuccess(isSuccess);
        },400);
    }

    const triggerNo = () => {
        triggerFinishQuestions(false);
    }

    useEffect(() => {
        if(isSuccess !== undefined){
            if (finishButtonRef.current) {
                finishButtonRef.current.style.opacity = "0";

                setTimeout(() => {
                    if (finishButtonRef.current)
                        finishButtonRef.current!.style.opacity = "1";
                }, 2100)
            }

            if(redCrossRef.current){
                redCrossRef.current!.style.opacity = "0";

                setTimeout(() => {
                    if(redCrossRef.current)
                        redCrossRef.current!.style.opacity = "1";
                }, 600)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess])

    return (
        <div ref={mainRef} className='stepladder-main'>
            <GoBack onGoBack={triggerHide} />
            {typeLadder === undefined && (
                <div ref={stepladderContentRef} className="stepladder-content">
                    <h1 className="stepladder-section">Utilisation permis échelle/escabeau</h1>
                    <div className="stepladder-separator" />
                        
                    <div className='stepladder-button' onClick={() => triggerSetTypeLadder('ladder')}>
                        <img className='stepladder-button-img' alt='' src={LadderImg} />
                        <div className='stepladder-button-fade'/>
                        <p className='stepladder-button-text'>Echelle portable</p>
                        <p className='stepladder-button-info'></p>
                    </div>

                    <div className="stepladder-separator">
                        <p style={{color:"#a8a8a8", fontWeight: 'bold'}}>OU</p>
                    </div>

                    <div className='stepladder-button' onClick={() => triggerSetTypeLadder('stepladder')}>
                        <img className='stepladder-button-img' alt='' src={StepladderImg} />
                        <div className='stepladder-button-fade'/>
                        <p className='stepladder-button-text'>Escabeau</p>
                        <p className='stepladder-button-info'></p>
                    </div>
                </div>
            )} 
            {isSuccess === undefined && typeLadder !== undefined && (
                <div ref={stepladderStepContainerRef} className="stepladder-stepper-container">
                    <Stepper 
                        currentStep={step}
                        items={(typeLadder === 'ladder' ? ladderQuestions : stepladderQuestions)
                            .map((question) => ({
                                title: question.title, 
                                content: buildQuestionsSteppers(question.description, triggerYes, triggerNo)
                        }))}
                    />
                </div>
            )}
           {isSuccess === true && (
                <div className="stepper-finish-container">
                    {typeLadder === 'ladder' && (
                        <>
                            <h1>En utilisant l'échelle, je m'engage à respecter les exigences suivantes :</h1>
                            <p>L'échelle doit être fixée en haut et en bas ou fixée en haut et
                            maintenue par une deuxième personne en bas.</p>
                            <p>Étendre les échelles droites à 1 mètre au-dessus du plancher à
                            atteindre.</p>
                            <p>Installer l'échelle sur un sol plat et contre une surface stable.</p>
                            <p>Assurer un angle d'ouverture de l'échelle de maximum 75° par
                            rapport au plan du sol (1 longueur de pied d'echelle pour chaque 4
                            longueurs d'échelle déployées).</p>
                            <p>Installez l'échelle loin des portes. Si nécessaire, bloquez la porte et
                            placez des panneaux d'avertissement.</p>
                            <p>Faites face à l'échelle lorsque vous montez ou descendez.</p>
                            <p>Prenez votre temps lorsque vous montez sur une échelle.</p>
                            <p>Ne pas transporter d'objets sur l'échelle.</p>
                            <p>Maintenez un contact à 3 points (deux mains et un pied, ou deux
                            pieds et une main) sur l'échelle lorsque vous montez et descendez.</p>
                            <p>N'utilisez jamais les deux dernières marches ou les deux derniers
                            échelons d'une échelle.</p>
                            <p>Gardez votre corps centré lorsque vous travaillez sur l'échelle. Ne pas
                            tendre les bras sur les côtés.</p>
                            <p>Ne laissez jamais plus d'une personne monter sur l'échelle à la fois.</p>
                            <h2>EN PLUS POUR LES ÉCHELLES COULISSANTES :</h2>
                            <p>Suivez toujours les instructions du fabricant.</p>
                            <p>Assurez-vous que tous les verrous sont enclenchés.</p>
                        </>
                    )}
                    {typeLadder === 'stepladder' && (
                        <>
                            <h1>En utilisant l'escabeau, je m'engage à respecter les exigences suivantes :</h1>
                            <p>Il est interdit d'utiliser un escabeau en position fermée.</p>
                            <p>Installer l'escabeau sur un sol plat.</p>
                            <p>Installer l'escabeau loin des portes ou bloquer la porte et poser des
                            panneaux d'avertissement.</p>
                            <p>Assurez-vous que l'escabeau est complètement ouvert et que le
                            dispositif de retenue de l'ouverture/fermeture est verrouillé.</p>
                            <p>Faites face à l'escabeau lorsque vous montez ou descendez.</p>
                            <p>Prenez votre temps lorsque vous montez sur un escabeau.</p>
                            <p>Ne transportez pas d'objets sur l'escabeau.</p>
                            <p>Maintenez un contact à 3 points (deux mains et un pied, ou deux
                            pieds et une main) sur l'escabeau lorsque vous montez et descendez.</p>
                            <p>N'utilisez jamais les deux dernières marches ou les deux derniers
                            échelons d'un escabeau.</p>
                            <p>Gardez votre corps centré lorsque vous travaillez sur l'escabeau. Ne
                            pas tendre les bras sur les côtés.</p>
                            <p>Ne laissez jamais plus d'une personne monter sur l'escaveau à la fois.</p>
                        </>
                    )}
                    <button ref={finishButtonRef} onClick={() => triggerHide(typeLadder)} className="petra-button stepper-finish-button">
                        J'ai lu et m'engage à respecter les exigences
                    </button>
                </div>
           )}
           {isSuccess === false && (
                <div className="stepper-finish-container">
                    <div className="stepper-finish-img-container">
                        {typeLadder === 'ladder' && (
                            <img src={LadderImg} alt="échelle" style={{height:"100%", width: "100%"}} />
                        )}
                        {typeLadder === 'stepladder' && (
                            <img src={StepladderImg} alt="escabeau" style={{height:"100%", width: "100%"}} />
                        )}
                        <img ref={redCrossRef} src={CrossRedImg} alt="échelle" style={{height:"80%", width: "80%", animationName:"fadeInFromCenter"}} />
                    </div>

                    <h1>Ne pas utiliser cet équipement</h1>
                    <p>Informer HSE - {(typeLadder === 'ladder' ? "L'échelle" : "L'escabeau" )} <b>doit être mis hors service</b> et
                    marqué en attendant d'être réparé ou détruit.</p>

                    <button ref={finishButtonRef} onClick={() => triggerHide()} className="petra-button stepper-finish-button">
                        OK
                    </button>
                </div>
           )}
        </div>
    );
}

export default StepladderPermit;